import React from "react";
import "./curriculum.scss";
import ReactPlayer from "react-player";
import { ArrowRight } from "tabler-icons-react";
import CurriculumText from "../CurriculumText/CurriculumText";
import useApiState from "../../store/store";
import { filterKeyword } from "../../utils/filterKeywords";
import { useNavigate } from "react-router-dom";
import CurriculumImage from "../../images/curriculum.png";

const Curriculum = () => {
  const { allData } = useApiState();
  const matchedData = filterKeyword(allData?.singlePageContent?.data, [
    "programs",
  ]);

  const academics = ["montessori", "school", "plus_2", "bachelors"];

  const matchedItems = filterKeyword(
    allData?.singlePageContent?.data,
    academics
  );
  const academicsOrder = [
    "montessori",
    "school",
    "secondary",
    "plus_2",
    "bachelors",
  ];
  const sortMatchedItems = matchedItems
    ?.filter((item) => academicsOrder.includes(item?.keyword))
    .sort((a, b) => {
      const academicsA = academicsOrder.indexOf(a.keyword);
      const academicsB = academicsOrder.indexOf(b.keyword);
      return academicsA - academicsB;
    });
  const programContent =
    // matchedData?.[0]?.content?.length > 220
    //   ? matchedData?.[0]?.content?.substring(0, 220) + "..."
    //   :
    matchedData?.[0]?.content;

  let navigate = useNavigate();
  return (
    <div className=" curriculum-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 video-section">
            <p className="container_title">OUR PROGRAMS</p>
            <h3 className="sub_headingB">
              Discover our top-notch programs we are home for pupils
            </h3>
            <div className="video-player">
              {/* <ReactPlayer
                url="https://youtu.be/9JdugskAcS0?si=WbM9klB1TLHu6BFO"
                width="100%"
                height="330px"
                controls
              /> */}
              <img
                src={
                  matchedData?.[0]?.title_image_link
                    ? matchedData?.[0]?.title_image_link
                    : CurriculumImage
                }
                style={{ width: "100%", height: "330px", objectFit: "cover" }}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 curriculum-section">
            <p className="descB">
              <div
                dangerouslySetInnerHTML={{
                  __html: programContent,
                }}
              />
            </p>
            <div className="row" style={{ rowGap: "32px" }}>
              {sortMatchedItems.map((cur, index) => {
                const iconName = cur.icon
                  .split("-")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join("");
                return (
                  <CurriculumText
                    title={cur.title}
                    subtitle={cur.subtitle}
                    icon={iconName}
                  />
                );
              })}
            </div>
            <button
              className="viewAll_btn"
              style={{ background: "transparent" }}
              onClick={() => {
                navigate("/admission_form");
              }}
            >
              Enroll Now
              <ArrowRight size={20} style={{ marginBottom: "3px" }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Curriculum;
